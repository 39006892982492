import React from "react";
import HeroXaalado from "../../components/hero/HeroXaalado";
import waraysi1 from "../../assets/audio/waraysi-j-m-c.mp3";
import waraysi2 from "../../assets/audio/waraysi-khadiija.mp3";
import waraysi3 from "../../assets/audio/waraysi-m-y-c.mp3";
import waraysi4 from "../../assets/audio/inaceydiid.mp3";
import { images } from "../../assets/images";
import "./xaalado.scss"

const Xaalado = () => {
  return (
    <div>
      <HeroXaalado />
      <section className="section3">
      <div className="container">            
            <div className="xaalada">           
            <div className="xaalada__content">
            <img src={images.logo} alt="logo" />
            <h2 className="title1 title1--mod">Warysiyada dad ka caafimaadey cudarada dilaaga ah: </h2>   
            <p>xarunta Asal Herbal, uu Rabbi u sahlay in ey hesho daawooyin lagu daaweeyo dadka qaba cudurada caanka ah sida  HIV/AIDS, Hepatitis B iyo C,Cancerka iyo cudurra kale.</p>          
            <h3>hoos ka dhageyso wareysiyada</h3>
            </div>  
            <div className="xaalada__list">   
            <div className="wrapper">                    
            <img src={images.wareysitwo} alt="" />              
              <p>
                Waraysiga ina caydiid  ee ku caafimaadey dawada asalherbal
              </p>
              <audio controls>
                <source src={waraysi4} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>           
            </div>                     
            <div className="wrapper">                    
            <img src={images.wareysitwo} alt="" />              
              <p>
                Waraysiga Jaamac Maxamed ee ku caafimaadey dawada asalherbal
              </p>
              <audio controls>
                <source src={waraysi1} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>           
            </div>                  
            <div className="wrapper">                    
            <img src={images.wareysithree} alt="" />              
              <p>
                Wareysiga Khadiija ee ka waramaysa gabadh laga daweeyey cudurka Cancerka.
              </p>
              <audio controls>
                <source src={waraysi2} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>            
            </div>                  
            <div className="wrapper">                    
            <img src={images.wareysifour} alt="" />
              <p>
                 Waraysiga Maxamed Yuusuf ee laga daweeyey cudurka Aids'ka.
              </p>
              <audio controls>
                <source src={waraysi3} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>          
            </div>                 
                        
            </div>
            <div className="xaalada__info">
              <p> waxaa marba marka ka danbeeya kusoo badanaya xarunta asli herbal dadka qaba cuduradaas caanka ah, ayada oo ilaa iyo haddana ay jriaan dad badan oo kaddib markii la siiyay mudaawaadka isticmaalo laga daaweeyay cuduradaas halista ah. </p>
              <p> Ciddii uu ku dhacay mid ka mid ah cudurada caanka ah sida  HIV/AIDS, Hepatitis B iyo C iyo cudarada kale ee dooneysa in ay iska daaweyso, waxay la xariiri kartaa xarunta Asal Herbal. </p>
            </div>   
            </div>
                
          
          </div>
      </section>

    </div>
  );
};

export default Xaalado;
