import React from "react";
import { images } from "../../assets/images";
import "./footer.scss"

const Footer = () => {
  return <div>
    <footer>
      <div className="footer-art" >
      <picture>
          <source media="(max-width: 767px)" srcSet={images.footer_mob} />
          <img src={images.footer} alt="" />
        </picture>
      </div>
       <div className="container" >
        <div className="footer">
          <div className="footer-header">
              <div className="logo">
                <img src={images.logo} alt="logo" />
              </div>
              <div className="social-media-icons">
              <img src={images.fb} alt="icons" />
              <img src={images.insta} alt="icons" />
              <img src={images.twitter} alt="icons" />
              </div>
          </div>
          {/* <div className="footer-menu">
              <div className="ku-saabsan">
                 <h4>Macluumaadka</h4>
                 <ul>
                  <li>nagu saabsan</li>
                  <li>Dawooyinka</li>
                  <li>nala soo xiriir</li>
                 </ul>
              </div>
          </div> */}
        </div>
       </div>
    </footer>
  </div>;
};

export default Footer;
