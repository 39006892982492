import React from "react";
import "./hero.scss";
import { images } from "../../assets/images";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div>
      <div className="hero">
        <div className="right-top-image">
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet={images.intro_right_mobile}
            />
            <img src={images.intro_right} alt="" />
          </picture>
        </div>
        <div className="hero__wrapper container">
          <div className="hero__content">
            <h1 className="title1">
              Dawo <br /> dabiici ah.
            </h1>
            <p className="hero__text">
              Asal herbal ugu kaaley dawo dhireed dabiici ah oo tayo sare leh
              xarunta waxaa lagu daweeyaa cudurada caanka ah iyo xanuunnada ku
              dhaca raga iyo dumarka.
            </p>
            <Link to="/dawooyin"><button className="btn">sii akhri</button></Link>
          </div>
          <div className="hero__image">
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet={images.intro_mobile}
            />
            <img src={images.intro_desktop} alt="" />
          </picture>
        </div>
        </div> 
        <div className="left-bottom-image" >
        <picture>
            <source
              media="(max-width: 767px)"
              srcSet={images.intro_left_mobile}
            />
            <img src={images.intro_left} alt="" />
          </picture>
          </div>      
      </div>
    </div>
  );
};

export default Hero;
