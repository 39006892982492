export const images = {
  // logo: require("./logo.svg").default,
  logo: require("./web.svg").default,
  // logo: require("./logoforweb.svg").default,
  intro_desktop: require("./image-intro-desktop.jpg"),
  intro_mobile: require("./image-intro-mobile.jpg"),
  intro_left: require("./bg-pattern-intro-left-desktop.svg").default,
  intro_left_mobile: require("./bg-pattern-intro-left-mobile.svg").default,
  intro_right: require("./bg-pattern-intro-right-desktop.svg").default,
  intro_right_mobile: require("./bg-pattern-intro-right-mobile.svg").default,
  waraysione: require("./one.svg").default,
  wareysithree: require("./three.svg").default,
  wareysitwo: require("./two.svg").default,
  wareysifour: require("./four.svg").default,
  works_desktop: require("./bg-pattern-how-we-work-desktop.svg").default,
  works_mobile: require("./bg-pattern-how-we-work-mobile.svg").default,
  fb: require("./icon-facebook.svg").default,
  twitter: require("./icon-twitter.svg").default,
  pinterest: require("./icon-pinterest.svg").default,
  insta: require("./icon-instagram.svg").default,
  footer: require("./bg-pattern-footer-desktop.svg").default,
  footer_mob: require("./bg-pattern-footer-mobile.svg").default,
  hamburger: require("./icon-hamburger.svg").default,
  about_desktop: require("./image-about-desktop.jpg"),
  about_mobile: require("./image-about-mobile.jpg"),
  whatsapp: require("./whatsapp-icon.svg").default,
  dawo_desktop: require("./image-dawo-desktop.png"),
  dawo_mobile: require("./image-dawo-mobile.png"),
  xaalado_desktop: require("./image-xaalado-desktop.jpg"),
  xaalado_mobile: require("./image-xaalado-mobile.jpg")
};
