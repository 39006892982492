import React, {useState} from "react";
import "./header.scss";
import { images } from "../../assets/images";
import { Link } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false)
  const handleClick = (e) => {
    e.preventDefault()    
    setOpen(!open)
  }
  return (
    <div>
      <header>
        <nav className="navbar container">
          <div className="logo">
           <Link to="/"><img src={images.logo} alt="logo" /></Link> 
          </div>
          <ul className={open ? `nav-items active` : `nav-items`}>
            <Link style={{color: 'green'}} to="/about"><li>Nagu Saabsan</li> </Link>
            <Link style={{color: 'green'}} to="/dawooyin"><li>Dawada</li></Link>
            {/* <li>Contact</li> */}
            <Link style={{color: 'green'}} to="/xaalado"><li>xaalada caafimaad</li></Link>
          </ul>
          <div className="hamburger">
            <img src={images.hamburger} alt="hamburger" onClick={handleClick}/>            
          </div>
         
        </nav>
        <div class="call">
        <img src={images.whatsapp} alt="Whatsapp" />
        0618350669
      </div>
      </header>
    </div>
  );
};

export default Header;
