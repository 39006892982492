import React from "react";
import { images } from "../../assets/images";
import waraysi1 from "../../assets/audio/waraysi-j-m-c.mp3";
import waraysi2 from "../../assets/audio/waraysi-khadiija.mp3";
import waraysi3 from "../../assets/audio/waraysi-m-y-c.mp3";
import waraysi4 from "../../assets/audio/inaceydiid.mp3";

import "./info.scss";
const Info = () => {
  return (
    <div>
      <div className="dawynta-cudurada">
        <div className="container">
          <h2 className="title1 title1--mod">Xaalada ku caafimaadey</h2>
          <div className="dawynta-cudurada__content">

          <div className="wareysi4">
              <img src={images.wareysitwo} alt="" />
              <h3>Ina Ceydiid </h3>
              <p>
                Waraysiga Ina Ceydiid ee ku caafimaadey dawada asalherbal
              </p>
              <audio controls>
                <source src={waraysi4} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
           
            <div className="wareysi1">
              <img src={images.wareysitwo} alt="" />
              <h3>Jaamac Maxamed </h3>
              <p>
                Waraysiga Jaamac Maxamed ee ku caafimaadey dawada asalherbal
              </p>
              <audio controls>
                <source src={waraysi1} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
            <div className="wareysi2">
              <img src={images.wareysithree} alt="" />
              <h3>Khadiija </h3>
              <p>
                Khadiija ee ka waramaysa gabadh laga daweeyey cudurka Cancerka.
              </p>
              <audio controls>
                <source src={waraysi2} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
            <div className="wareysi3">
              <img src={images.wareysifour} alt="" />
              <h3>Maxamed Yuusuf</h3>
              <p>
                 Waraysiga Maxamed Yuusuf ee laga daweeyey cudurka Aids'ka.
              </p>
              <audio controls>
                <source src={waraysi3} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
