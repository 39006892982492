import React from "react";
import HeroAbout from "../../components/hero/HeroAbout";
import { images } from "../../assets/images";
import "./about.scss";

const About = () => {
  return (
    <div>
      <HeroAbout />
      <section className="section3">
        <div className="about">
          <div className="container">
            <img src={images.logo} alt="logo" />
            <div className="about__content">
              <p>
                waa xarun ku xeel dheer daweynta cudurada caanka ah sida
                Aids'ka, cancerka, macaanka, diig karka, iyo beer xanuunka.{" "}
                <br />
                xarunta sidoo kale waxaa lagu daweeyaa cudurada ku dhaca raga
                iyo dumarka.
              </p>
            </div>
            <h2 className="title1 title1--mod">Nagala soo xiriir:</h2>
            <div className="about__contact">
              <img src={images.whatsapp} alt="logo" />
              <div class="wrapper">
                <h4>
                  <b>Soo Wac</b>
                </h4>
                <p>0618350669</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
