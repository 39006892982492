import React from "react";
import HeroDawo from "../../components/hero/HeroDawo";
import { images } from "../../assets/images";
import "./dawo.scss";

const Dawo = () => {
  return (
    <div>
      <HeroDawo />
      <section className="section3">
        <div className="dawo">
          <div className="container">
            <img src={images.logo} alt="logo" />
            <div className="dawo__content">
            <h2 className="title1 title1--mod">Cuduradda Lagu Daweeyo Waxaa ka mid ah:</h2>             
            </div>            
            <div className="dawo__list">
            <div className="wrapper">                    
              <p>Aids'ka </p>
            </div>                  
            <div className="wrapper">                    
              <p>Cancerka</p>             
            </div>                  
            <div className="wrapper">                    
              <p>Beer xanuunka</p>             
            </div>                  
            <div className="wrapper">                    
              <p>Macaanka</p>             
            </div>                  
            <div className="wrapper">                    
              <p>Diig Karka</p>             
            </div>                  
            <div className="wrapper">                    
              <p>Gaaska</p>             
            </div>                  
            <div className="wrapper">                    
              <p>Baabasiirta</p>             
            </div>                  
            <div className="wrapper">                    
              <p>Caabuqa</p>             
            </div>                  
            </div>
            <div className="dawo__info">
              <p>Sidoo kale xarunta waxaa lagu daweeyaa xanuunada ku dhaca raga si gaar ah sida ragnimada iyo xanuuna kale iyo xanuunada ugaarka ah dumarka sida umu sibyaanta iyo caada wareerka.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dawo;
