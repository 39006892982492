import React from "react";
import "./hero.scss";
import { images } from "../../assets/images";

const HeroDawo = () => {
  return (
    <div>
      <div className="hero">
        <div className="right-top-image">
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet={images.intro_right_mobile}
            />
            <img src={images.intro_right} alt="" />
          </picture>
        </div>
        <div className="hero__wrapper container">
          <div className="hero__content">
            <h1 className="title1">
              xaalado caafimaad.
            </h1>
           
          </div>
          <div className="hero__image">
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet={images.dawo_mobile}
            />
            <img src={images.dawo_desktop} alt="" />
          </picture>
        </div>
        </div> 
        <div className="left-bottom-image" >
        <picture>
            <source
              media="(max-width: 767px)"
              srcSet={images.intro_left_mobile}
            />
            <img src={images.intro_left} alt="" />
          </picture>
          </div>      
      </div>
    </div>
  );
};

export default HeroDawo;
