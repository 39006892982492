import React from "react";
import { Link } from "react-router-dom";
import { images } from "../../assets/images";
import "./banner.scss";
const Banner = () => {
  return (
    <div>
      <div className="banner container">
        <picture>
          <source media="(max-width: 767px)" srcSet={images.works_mobile} />
          <img src={images.works_desktop} alt="" />
        </picture>
        <div className="banner__wrapper">
          <div className="title">
            <h2 className="title1">
              Xagaan Ka Hel <br /> Faahfaahin Dheeraad ah
            </h2>
          </div>
          <div className="button">
            <Link to="/about">
              <button className="btn">SII AKHRI</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
